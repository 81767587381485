import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { Geography } from '~/models'
import type { ApiResponse } from './utils'

type FetchGeographiesResponse = ApiResponse & {
  geographies: Geography[]
  count: number
  nextOffset: number
}

async function fetchGeographies({
  find,
  sort,
  project,
  limit,
  offset,
  signal,
}: {
  find: Record<string, any>
  sort?: Record<string, any>
  project?: {}
  limit?: number
  offset?: number
  signal?: AbortSignal
}): Promise<FetchGeographiesResponse> {
  const response = await fetch('/api/geographies', {
    body: JSON.stringify({
      find,
      sort,
      project,
      limit,
      offset,
    }),
    method: 'POST',
    signal,
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error loading geographies', result.error)
    throw new Error(`There was a problem fetching results: ${result.error}`)
  }
  return result
}

export function useGeographiesQuery({
  key,
  find,
  sort,
  project,
  keepPreviousData = true,
  refetchOnWindowFocus = true,
  refetchOnMount = true,
  refetchInterval,
  enabled,
}: {
  key: string[]
  find?: { [key: string]: any }
  sort?: { [key: string]: number }
  project?: {}
  keepPreviousData?: boolean
  refetchOnWindowFocus?: boolean
  refetchOnMount?: boolean
  refetchInterval?: number
  enabled?: boolean
}) {
  const queryClient = useQueryClient()
  const geographiesQuery = useQuery({
    queryKey: key,
    queryFn: !find
      ? undefined
      : () =>
          fetchGeographies({
            find,
            sort,
            project,
          }),
    // keepPreviousData,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchInterval,
    enabled: enabled && !!find,
  })
  return {
    isLoading:
      geographiesQuery.isLoading ||
      geographiesQuery.isFetching ||
      geographiesQuery.isRefetching,
    isError: geographiesQuery.isError,
    geographies: geographiesQuery.data?.geographies || [],
    refetch: geographiesQuery.refetch,
    updateGeographyData: (
      updateFunc: (previousGeographies: Geography[]) => Geography[]
    ) => {
      return queryClient.setQueryData(
        key,
        (previousData?: { success: boolean; geographies: Geography[] }) => {
          if (!previousData) return undefined
          return {
            ...previousData,
            geographies: updateFunc(previousData.geographies),
          }
        }
      )
    },
  }
}
